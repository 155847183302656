import React, { useState } from "react";
import styles from "../RedirectsDisplay.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faPencil, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import Modal from "src/Components/Modal";
import { RedirectTableType, PageRedirectType, RedirectTableRowType } from "../types";
import axios, { AxiosError } from "axios";
import env from "../../../../../../environment.json";

export default function RedirectTable({
  redirectPages,
  fetchAllPageRedirects,
  setIsLoading,
}: RedirectTableType): JSX.Element {
  const [confirmDeleteRedirect, setConfirmDeleteRedirect] = useState<Number>(0);

  /**
   * Delete a redirect from the backend
   *
   * @author    Alvin Mwakai
   */
  function delete_redirect() {
    const redirectID = confirmDeleteRedirect;

    if (!redirectID) {
      return;
    }

    axios
      .post(env.protocol + env.env + "/api/secured/pages/redirects/DeleteRedirect", { redirectID })
      .then(() => {
        fetchAllPageRedirects();
      })
      .catch((error: AxiosError) => {
        console.error("Error deleting redirect:", error);
      })
      .finally(() => {
        setConfirmDeleteRedirect(0);
      });
  }

  return (
    <div className={styles.tableContainer}>
      <table>
        <thead>
          <tr>
            <th>PAGE</th>
            <th>Redirect to</th>
            <th>Redirect from</th>
          </tr>
        </thead>
        <tbody>
          {redirectPages.map((page) => {
            return (
              <RedirectTableRow
                page={page}
                key={page.pageLanguageID.toString()}
                confirmDeleteRedirect={(redirectID: Number) => {
                  setConfirmDeleteRedirect(redirectID);
                }}
                fetchAllPageRedirects={fetchAllPageRedirects}
                setIsLoading={setIsLoading}
              />
            );
          })}
        </tbody>
      </table>

      {/* Delete Redirect Modal */}
      {confirmDeleteRedirect !== 0 && (
        <Modal
          title="Delete Redirect"
          type="confirmation"
          confirmButtonText="Delete"
          cancelButtonText="Cancel"
          close={() => {
            setConfirmDeleteRedirect(0);
          }}
          onConfirm={delete_redirect}
          onCancel={() => {
            setConfirmDeleteRedirect(0);
          }}
        >
          <div className={styles.modalContent}>
            <p>Are you sure you want to delete this redirect? Any links to this slug will be dead</p>
          </div>
        </Modal>
      )}
    </div>
  );
}

/**
 * Render a redirect table row
 */
function RedirectTableRow({
  page,
  confirmDeleteRedirect,
  fetchAllPageRedirects,
  setIsLoading,
}: RedirectTableRowType): JSX.Element {
  const [editing, setEditing] = useState<boolean>(false);
  const [slug, setSlug] = useState<string>(page.slug);
  const [pageLanguageID] = useState<Number>(page.pageLanguageID);

  /**
   * onChange when updating a slug
   *
   *
   * @author    Alvin Mwakai
   */
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSlug(e.currentTarget.value);
  };
  const cancel = () => {
    setSlug(page.slug);
    setEditing(false);
  };

  /**
   * Called when the save button is clicked
   *
   */
  function editSlug() {
    if (slug !== page.slug) {
      edit_redirect(page.slug, slug);
    } else {
      setEditing(false);
    }
  }

  /**
   * Edits or Updates the slug in the backend
   *
   * @param oldSlug
   * @param newSlug
   * @returns {void}
   *
   * @author    Alvin Mwakai
   */
  function edit_redirect(oldSlug: string, newSlug: string) {
    if (!oldSlug || !newSlug || !pageLanguageID) {
      return;
    }

    // Start the loading spinner here
    setIsLoading(true);

    axios
      .post(env.protocol + env.env + "/api/secured/pages/redirects/AddPageRedirect", {
        oldSlug,
        newSlug,
        pageLanguageID,
        slug: newSlug,
      })
      .then(() => {
        fetchAllPageRedirects();
      })
      .catch((error: AxiosError) => {
        console.error(error);
      });
  }

  return (
    <tr>
      <td>
        {page.title} [{page.language}] - {page.siteName}
      </td>
      <td>
        {editing ? (
          <div className={styles.editContainer}>
            <input type="text" value={slug} onChange={onChange} autoFocus />
            <div className={styles.buttonContainer}>
              <button className={styles.saveButton} onClick={editSlug}>
                <FontAwesomeIcon icon={faCheck as IconProp} />
              </button>
              <button className={styles.cancelButton} onClick={cancel}>
                <FontAwesomeIcon icon={faTimes as IconProp} />
              </button>
            </div>
          </div>
        ) : (
          <>
            {slug}
            <button
              className={styles.edit}
              onClick={() => {
                setEditing(true);
              }}
            >
              <FontAwesomeIcon icon={faPencil as IconProp} />
            </button>
          </>
        )}
      </td>
      <td>
        {page.redirects.map((redirect) => {
          return (
            <PageRedirect
              redirect={redirect}
              key={redirect.redirectID.toString()}
              confirmDeleteRedirect={confirmDeleteRedirect}
            />
          );
        })}
      </td>
    </tr>
  );
}

/**
 * Render a single page redirect item
 */
function PageRedirect({ redirect, confirmDeleteRedirect }: PageRedirectType): JSX.Element {
  return (
    <div className={styles.redirectItem}>
      {redirect.slug}
      <button
        className={styles.delete}
        onClick={() => {
          confirmDeleteRedirect(redirect.redirectID);
        }}
      >
        <FontAwesomeIcon icon={faTrashAlt as IconProp} />
      </button>
    </div>
  );
}
